import React from "react";
import TLink from "../components/tlink"

import Layout from "../components/layout"

const IndexPage = () => (
    <Layout>
        <div className='max-w-xl mx-auto px-4 sm:px-6'>
            <div className='flex justify-center align-center mt-12 text-5xl font-bold tracking-wide'>
                Tools
            </div>

            <div className='flex justify-center align-center mt-24 text-xl tracking-wide'>
                <TLink href='/estimate/' title='ESTIMATION TOOL' />
                <span className='ml-2'> - helps teams in realtime estimation efforts </span>
            </div>
        </div>

    </Layout>
);

export default IndexPage